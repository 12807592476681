'use client';

import styled, { css } from 'styled-components';

export type ButtonSizeType = 'xsm' | 'sm' | 'md';

export const Wrapper = styled.button<{
  $fullWidth?: boolean;
  $variant?: 'outlined' | 'secondary' | 'primary' | 'third';
  $size?: ButtonSizeType;
}>`
  position: relative;
  z-index: 5;
  overflow: hidden;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(85.66deg, #af26ed 30.77%, #1d97f3 92.01%);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-decoration: none;

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
  }

  ${(props) => {
    switch (props.$size) {
      case 'sm': {
        return css`
          font-size: 12px;
          min-height: auto;
          line-height: 1;
          min-width: 132px;
          padding: 15px;
        `;
      }

      case 'xsm': {
        return css`
          font-size: 12px;
          min-height: auto;
          line-height: 1;
          padding: 6px;
        `;
      }

      case 'md':
      default: {
        return css`
          font-size: 16px;
          height: 56px;
          min-width: 160px;
          padding: 15px 22px;
        `;
      }
    }
  }}

  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) => {
    switch (props.$variant) {
      case 'secondary':
        return css`
          background: rgba(255, 255, 255, 0.1);
          border: none;
          color: white;
        `;
      case 'outlined':
        return css`
          background: transparent;
          border: 1px solid rgba(255, 255, 255, 0.2);
          color: white;

          &:hover {
            border-color: transparent;
          }
        `;
      case 'third':
        return css`
          background: #ffffff;
          color: #000000;
        `;
      default:
        return css``;
    }
  }}
`;
